module.exports={
  "name": "xsolla-login-js-sdk",
  "version": "2.2.6",
  "description": "",
  "main": "src/main.js",
  "scripts": {
    "build": "gulp build",
    "host": "static-server . -p 8084",
    "test": "jest"
  },
  "author": "",
  "license": "MIT",
  "dependencies": {
    "to-snake-case": "^1.0.0"
  },
  "devDependencies": {
    "@babel/core": "^7.4.5",
    "@babel/preset-env": "^7.4.5",
    "babel-jest": "^24.8.0",
    "babel-plugin-transform-object-assign": "^6.22.0",
    "babel-preset-es2015": "^6.18.0",
    "babelify": "^7.3.0",
    "bower": "^1.8.8",
    "brfs": "^2.0.1",
    "browser-sync": "^2.26.7",
    "browserify": "^16.2.3",
    "browserify-istanbul": "^2.0.0",
    "browserify-shim": "^3.8.12",
    "common-shakeify": "^0.6.0",
    "gulp": "^4.0.2",
    "gulp-if": "^2.0.2",
    "gulp-rename": "1.2.0",
    "gulp-sourcemaps": "^2.6.5",
    "gulp-strip-comments": "^2.5.2",
    "gulp-uglify": "^3.0.1",
    "gulp-util": "3.0.6",
    "jasmine": "^2.4.1",
    "jest": "^24.8.0",
    "jsdom": "^15.1.1",
    "static-server": "2.2.1",
    "url-parse": "^1.4.7",
    "vinyl-buffer": "^1.0.1",
    "vinyl-source-stream": "^2.0.0",
    "watchify": "^3.11.1"
  },
  "browserify-shim": {
    "external": "global:External"
  }
}
